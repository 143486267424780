/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/datatables-responsive/css/responsive.dataTables.scss";
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-connect-bar/ms-connect-bar.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "main/apps/mail/views/classic/detail/detail.scss";
@import "main/apps/mail/views/classic/list/list.scss";
@import "main/apps/mail/views/outlook/detail/detail.scss";
@import "main/apps/mail/views/outlook/list/list.scss";
@import "main/apps/chat/sidenavs/left/views/chats/chats-view.scss";
@import "main/apps/chat/sidenavs/left/views/contacts/contacts-views.scss";
@import "main/apps/chat/sidenavs/left/views/user/user-view.scss";
@import "main/apps/chat/sidenavs/right/views/contact/contact-view.scss";
@import "main/apps/scrumboard/sidenavs/settings/menus/color/color-menu.scss";
@import "main/apps/scrumboard/sidenavs/settings/menus/labels/labels-menu.scss";
@import "main/apps/scrumboard/sidenavs/settings/menus/members/members-menu.scss";
@import "main/apps/scrumboard/views/calendar/dialogs/event/event-dialog.scss";
@import "main/apps/scrumboard/views/calendar/sidenavs/scheduled-tasks/scheduled-tasks-sidenav.scss";
@import "quick-panel/quick-panel.scss";
@import "toolbar/toolbar.scss";
@import "main/components/components.scss";
@import "main/apps/cadastros/cadastros.scss";
@import "main/apps/calendar/calendar.scss";
@import "main/apps/chat/chat.scss";
@import "main/apps/contacts/contacts.scss";
@import "main/apps/e-commerce/e-commerce.scss";
@import "main/apps/file-manager/file-manager.scss";
@import "main/apps/gantt-chart/gantt-chart.scss";
@import "main/apps/mail/mail.scss";
@import "main/apps/notes/notes.scss";
@import "main/apps/scrumboard/scrumboard.scss";
@import "main/apps/todo/todo.scss";
@import "main/components/charts/charts.scss";
@import "main/components/maps/maps.scss";
@import "main/components/material-docs/material-doc-template.scss";
@import "main/components/price-tables/price-tables.scss";
@import "main/components/widgets/widgets.scss";
@import "main/pages/coming-soon/coming-soon.scss";
@import "main/pages/maintenance/maintenance.scss";
@import "main/pages/profile/profile.scss";
@import "main/pages/search/search.scss";
@import "main/pages/timeline/timeline.scss";
@import "main/ui/forms/forms.scss";
@import "main/ui/helper-classes/helper-classes.scss";
@import "main/ui/icons/icons.scss";
@import "main/ui/material-colors/material-colors.scss";
@import "main/ui/theme-colors/theme-colors.scss";
@import "main/ui/typography/typography.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.scss";
@import "quick-panel/tabs/activity/activity-tab.scss";
@import "quick-panel/tabs/chat/chat-tab.scss";
@import "quick-panel/tabs/today/today-tab.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.scss";
@import "main/apps/dashboards/analytics/dashboard-analytics.scss";
@import "main/apps/dashboards/project/dashboard-project.scss";
@import "main/apps/dashboards/server/dashboard-server.scss";
@import "main/pages/auth/forgot-password/forgot-password.scss";
@import "main/pages/auth/lock/lock.scss";
@import "main/pages/auth/login/login.scss";
@import "main/pages/auth/login-v2/login-v2.scss";
@import "main/pages/auth/register/register.scss";
@import "main/pages/auth/register-v2/register-v2.scss";
@import "main/pages/auth/reset-password/reset-password.scss";
@import "main/pages/errors/404/error-404.scss";
@import "main/pages/errors/500/error-500.scss";
@import "main/ui/page-layouts/demo/demo-content.scss";
@import "main/ui/page-layouts/demo/demo-sidenav.scss";
@import "main/apps/cadastros/views/cliente/cliente.scss";
@import "main/apps/cadastros/views/clientes/clientes.scss";
@import "main/apps/calendar/dialogs/event-detail/event-detail-dialog.scss";
@import "main/apps/calendar/dialogs/event-form/event-form-dialog.scss";
@import "main/apps/chat/sidenavs/left/left-sidenav.scss";
@import "main/apps/chat/sidenavs/right/right-sidenav.scss";
@import "main/apps/contacts/dialogs/contact/contact-dialog.scss";
@import "main/apps/contacts/sidenavs/main/main-sidenav.scss";
@import "main/apps/e-commerce/views/order/order.scss";
@import "main/apps/e-commerce/views/orders/orders.scss";
@import "main/apps/e-commerce/views/product/product.scss";
@import "main/apps/e-commerce/views/products/products.scss";
@import "main/apps/file-manager/sidenavs/details/details-sidenav.scss";
@import "main/apps/file-manager/sidenavs/main/main-sidenav.scss";
@import "main/apps/file-manager/views/grid/grid-view.scss";
@import "main/apps/file-manager/views/list/list-view.scss";
@import "main/apps/gantt-chart/dialogs/add-edit/add-edit-dialog.scss";
@import "main/apps/gantt-chart/sidenavs/settings/settings-sidenav.scss";
@import "main/apps/mail/dialogs/compose/compose-dialog.scss";
@import "main/apps/mail/sidenavs/main/main-sidenav.scss";
@import "main/apps/mail/views/classic/classic-view.scss";
@import "main/apps/mail/views/outlook/outlook-view.scss";
@import "main/apps/notes/directives/ms-new-note/ms-new-note.scss";
@import "main/apps/notes/directives/ms-note-form/ms-note-form.scss";
@import "main/apps/notes/directives/ms-note-item/ms-note-item.scss";
@import "main/apps/notes/sidenavs/main/main-sidenav.scss";
@import "main/apps/scrumboard/dialogs/card/card-dialog.scss";
@import "main/apps/scrumboard/directives/ms-sb-add-card/ms-sb-add-card.scss";
@import "main/apps/scrumboard/sidenavs/filters/filters-sidenav.scss";
@import "main/apps/scrumboard/sidenavs/settings/settings-sidenav.scss";
@import "main/apps/scrumboard/views/board/board-view.scss";
@import "main/apps/scrumboard/views/boards/boards-view.scss";
@import "main/apps/scrumboard/views/calendar/calendar-view.scss";
@import "main/apps/todo/dialogs/task/task-dialog.scss";
@import "main/apps/todo/sidenavs/main/main-sidenav.scss";
@import "main/apps/todo/views/list/list-view.scss";
@import "main/pages/invoice/views/compact/compact.scss";
@import "main/pages/invoice/views/modern/modern.scss";
@import "main/pages/profile/tabs/about/about.scss";
@import "main/pages/profile/tabs/photos-videos/photos-videos.scss";
@import "main/pages/profile/tabs/timeline/timeline.scss";
@import "main/pages/search/tabs/classic/classic.scss";
@import "main/pages/search/tabs/contacts/contacts.scss";
@import "main/pages/search/tabs/emails/emails.scss";
@import "main/pages/search/tabs/users/users.scss";
@import "main/ui/theme-colors/dialogs/custom-theme/custom-theme-dialog.scss";
// endinjector